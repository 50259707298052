<template>
<div class="certificate">
  <img :src="require('@/assets/images/certificate/zs1.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs2.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs3.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs4.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs5.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs6.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs7.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs8.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs9.png')" alt="">
  <img :src="require('@/assets/images/certificate/zs10.png')" alt="">
</div>
</template>

<script>
export default {
  name: "ZeroCertificate"
}
</script>

<style scoped>
.certificate img{
  width: 800px;
}
@media screen and (max-width: 991px){
  .certificate img{
    width: 90%;
    text-align: center;
  }
}
</style>
